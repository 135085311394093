@import "../bootstrap/src/scss/colors";

.barcode-alert {
  position: fixed;
  right: 2vw;
  bottom: 10vh;
  z-index: 100000;
  min-height: fit-content;
  width: 100%;
  max-width: 20rem;
  background-color: $success;
  border: 2px solid #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  padding: 16px 8px;
  color: #fff;
}
