@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.footer-title {
  height: 42px;

  @include media-breakpoint-down('lg') {
    height: fit-content;
    max-height: 42px;
  }

  img {
    width: 100%;
    object-fit: contain;

    @include media-breakpoint-down('lg') {
      width: auto;
      max-height: 42px;
    }
  }
}
