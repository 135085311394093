@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";

header {
  position: sticky;
  top: 0;
  flex-shrink: 0;
  z-index: 2;
  height: $header-height;

  .navbar {
    .logo {
      line-height: 50px;

      img {
        max-width: 200px;
        max-height: 64px;
        width: 100%;
        height: 100%;

        @include media-breakpoint-up('lg') {
          max-width: 250px;
        }
      }
    }

    .navbar-nav {

      .nav-item {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        letter-spacing: 0.1rem;

        @include media-breakpoint-up('lg') {
          &.action {
            border-left: 1px solid $gray-300;
          }
        }


        .nav-link {
          text-align: center;
          font-size: 14px;

          &.btn.nav-link-btn {
            color: $primary;
            border: 1px solid $primary;

            &:hover {
              background-color: $primary;
              color: #fff;
            }
          }

          @include media-breakpoint-up('lg') {
            padding-left: 12px;
            padding-right: 12px;
          }


          i {
            font-size: 24px;

            & + p {
              font-size: 10px;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .navbar-toggler {
      box-shadow: none;

      .bi-list {
        display: none;
      }

      .bi-x-lg {
        display: inline;
      }

      &.collapsed {
        .bi-list {
          display: inline;
        }

        .bi-x-lg {
          display: none;
        }
      }
    }
  }
}
