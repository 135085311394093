.page-banner-title {
  &.join {
    background-image: url(/web/front/banners/join-bg.jpg);
  }
}

ul li img {
  height: 30px;
  width: 40px;
  object-fit: cover;
}
