@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.hero {
  position: relative;
  //min-height: calc(100vh - $header-height);
  background-image: url(/web/front/hero-img.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0%;
    aspect-ratio: 1;
    background-color: rgba(0, 0, 0, 0.6);

    @include media-breakpoint-down(lg) {
      // display: none;
    }
  }
}

.recommendations {
  gap: 60px;

  @include media-breakpoint-down('lg') {
    gap: 16px;
  }

  a {
    flex: 0 0 auto;

    &:hover img {
      transition: opacity 0.3s ease;
      opacity: 0.5;
    }

    img {
      object-fit: contain;
      width: 100%;
      max-height: 60px;

      &.amazon {
        max-height: 45px;
      }
    }
  }
}

.hero-content {
  // max-width: 418px;

  h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: 1.3em;
  }

  h4 {
    font-weight: 300;
    line-height: 1.45em;
  }

  .recommendation-items {
    a img {
      max-height: 34px;
    }
  }
}

.money-back {
  position: absolute;
  bottom: 0;
  left: 0;
  //max-width: 172px;
  max-width: 120px;

  @include media-breakpoint-down(xl) {
    max-width: 120px;
  }
  @include media-breakpoint-down(md) {
    max-width: 100px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 172px;
  }
}

.buy-btn,
.flash-sale {
  h3 {
    font-size: 34px;
  }
}

.buy-btn {
  background: linear-gradient(90deg, #373d6b 0%, #7d8cff 100%);
  // width: 100%;
  display: inline-block;
  max-width: 395px;
  border-radius: 60px;
}

.flash-sale {
  position: relative;
  max-width: 316px;
  width: 100%;
  aspect-ratio: 1;
  padding: 86px 29px 75px;

  .leaf {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-20%);
  }

  h4 {
    line-height: 1.45em;
  }
}

.text-divider {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: #fff;
    width: 10%;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.accordion-button {
  gap: 12px;

  &:focus {
    box-shadow: none;
    border: none;
  }

  &:not(.collapsed) {
    background-color: transparent;
  }
}

.membership {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  &-first {
    background-image: url(/web/front/membership-1.jpg);
  }

  &-second {
    background-image: url(/web/front/membership-2.png);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.31);
  }

  a {
    letter-spacing: 0.3em;

    small {
      letter-spacing: 0.1em;
    }
  }
}

.contact-us {
  background-image: url(/web/front/contact-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .contact-icon {
    grid-area: contact-icon;
  }

  .contact-title {
    grid-area: contact-title;
  }

  .contact-info {
    grid-area: contact-info;
  }

  .contact-row {
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'contact-icon contact-title'
      '. contact-info';
  }
}
