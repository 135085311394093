@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../variables';

.steps {
  @include media-breakpoint-up('lg') {
    max-width: 80%;
  }

  li {
    flex: 1 0 33.33%;

    @include media-breakpoint-down('lg') {
      .display-1 {
        text-align: center;
        width: 1.75rem;
      }
    }
  }
}

.barcode-item {
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 0 12px 0 rgba(34, 60, 80, 0.2);

  img {
    max-width: 100%;
  }

  h3 {
    color: $dark;
  }

  &:hover {
    background-color: $primary;
    color: #fff;

    h3 {
      color: #fff;
    }
  }
}

.test-item {
  .test-item-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background-color: $pink-100;
  }

  .test-item-content {
    img {
      height: 1.25rem;
    }
  }

  &.google {
    .test-item-icon {
      background-color: $yellow-500;
    }
  }
  &.video {
    img {
      max-width: 100%;
      object-fit: contain;
      max-height: 384px;
    }
  }
}

.swiper-slide {
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  width: 48px;
  height: 48px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid $primary;
  transition: all 0.3s ease;

  &:hover {
    background-color: #fff;
  }

  &::after {
    color: $primary;
    font-size: 20px;
  }
}
