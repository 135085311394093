.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dashboard-card {
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #111111 !important;
  text-decoration: none !important;
}
.dashboard-card-body {
  padding: 15px;
  flex: 1;
}
.dashboard-card-icon {
  width: 80px;
  flex-shrink: 0;
  color: #a4c5fe;
  img {
    max-width: 100%;
  }
}
.dashboard-card-icon svg {
  width: 60px;
  height: 60px;
}
.dashboard-card-icon h4 {
  font-size: 1.5rem;
}
.dashboard-card-footer {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #111111;
  border-top: 1px solid #ccc;
}
