@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../colors";

.page-banner-title {
  &.blogs {
    background-image: url(/web/front/blogs-bg.jpg);
  }
}
.membership.blogs a{
  letter-spacing: 0.1em;
}
