@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import 'variables';
@import 'colors';
@import '@splidejs/splide/css';
@import '@splidejs/splide/css/core';
body {
  font-family: 'Inter', sans-serif;
  color: $text-color-default;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Antonio', sans-serif;
}

.lh-1 {
  line-height: 1;
}

.fz-14 {
  font-size: 14px;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-lg-10 {
  @include media-breakpoint-up('lg') {
    width: 10%;
  }
}

.mw-180 {
  max-width: 180px;
}

.mw-300 {
  max-width: 300px;
}

.mw-350 {
  max-width: 350px;
}

.mw-400 {
  max-width: 400px;
}

.mw-430 {
  max-width: 430px;
}

.mw-620 {
  max-width: 620px;
}

.mw-900 {
  max-width: 900px;
}

.mw-1000 {
  max-width: 1000px;
}

.aspect-ratio-1x1 {
  aspect-ratio: 1;
}

.primary-darken-10 {
  color: darken($primary, 10);
}

.primary-darken-15 {
  color: darken($primary, 15);
}

.bg-primary-darken-10 {
  background-color: darken($primary, 10);
}

.bg-primary-darken-15 {
  background-color: darken($primary, 15);
}

.divider-horizontal {
  &.h-1 {
    height: 1px;
  }

  &.h-2 {
    height: 2px;
  }

  &.h-3 {
    height: 3px;
  }

  &.h-4 {
    height: 4px;
  }

  &.h-5 {
    height: 5px;
  }
}

.page-banner-title {
  position: relative;
  padding: 120px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include media-breakpoint-down('lg') {
    padding: 60px 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.shield-layout {
  position: fixed;
  z-index: 9999;
  left: 0px;
  bottom: 5px;
  width: 90px;

  @include media-breakpoint-down('lg') {
    width: 60px;
  }
}

.verify-barcode.apps {
  img {
    height: 45px;
  }
}

.test-item {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 1px solid #e5e5dd;
  max-width: 630px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  // transform: scale(0.9);

  &-inner {
    padding: 24px;
  }
  &-header {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: $dark;
      opacity: 1;
    }
  }
  &-user {
    display: flex;
  }
  &-avatar {
    width: 44px;
    min-width: 44px;
    height: 44px;
    min-height: 44px;
    background-color: #fddce5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    user-select: none;
    cursor: default;
    span {
      font-size: 16px;
      font-weight: 900;
    }
  }
  &-profile {
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    align-self: center;
    overflow: hidden;
    margin-left: 8px;
    &-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 1;
    }
    &-bottom {
      display: flex;
      font-size: 14px;
      color: #696a6a;
    }
    &-location {
      display: flex;
      align-items: center;
      margin-left: 16px;
      color: #696a6a;
    }
  }
  &-divider {
    display: block;
    height: 1px;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    margin-top: 16px;
    background: #e5e5dd;
  }
  &-body {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
  &-rating {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &-star {
    width: 108px;
    height: 20px;
    img {
      max-width: 100%;
    }
  }
  &-date {
    order: 3;
    flex-grow: 1;
    text-align: right;
    font-size: 14px;
    color: #696a6a;
  }
  &-verified {
    order: 2;
    width: auto;
    margin-top: 0;
    margin-left: 16px;
    display: flex;
    align-items: center;
    color: #696a6a;
    font-size: 14px;
    svg {
      margin-right: 6px;
    }
  }
  &-content {
    margin-top: 16px;
    &-date {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
  &-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
  }
  &-text {
    margin-top: 8px;
    color: #191919;
  }
  &-footer {
    margin-top: 10px;
    margin-bottom: -12px;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
      color: $dark;
      opacity: 1;
    }
    &-link {
      display: flex;
      align-items: center;
      padding: 4px;
      color: #696a6a;
      margin-right: 8px;
      font-size: 14px;
      svg {
        margin-right: 4px;

        fill: #6c6c85;
      }
    }
    &-right {
      flex: 1 1;
      display: flex;
      justify-content: flex-end;
      color: #696a6a;

      font-size: 14px;
      svg {
        fill: #6c6c85;
      }
    }
  }
}
.test-wrapper {
  @media (max-width: 768px) {
    padding-bottom: 30px !important;
  }
  .test-item {
    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }
}
.test-item-img {
  border-radius: 8px;
  height: 320px;
  @media (max-width: 768px) {
    height: 450px;
    margin: auto;
    // width: 100%;
  }
}
.test-item-google {
  border: none;
  .test-item {
    &-avatar {
      background-color: #efa320;
    }
    &-date {
      flex-grow: 0;
    }
    &-content {
      margin-top: 0;
    }
  }
}

@import 'header';
@import 'footer';
@import 'pages/home';
@import 'pages/buy-barcodes';
@import 'pages/checkout';
@import 'pages/verify';
@import 'pages/blogs';
@import 'pages/faq';
@import 'pages/apps';
@import 'pages/contact-us';
@import 'pages/join';
@import 'pages/verify-barcode';
@import '~bootstrap-icons/font/bootstrap-icons.css';
