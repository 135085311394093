@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../colors";

.verify-barcode.inner {
  background-image: url(/web/front/banners/verify-barcode-inner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down('lg') {
      background-position: left center;
    }

  .container {
    padding-top: 120px;
    padding-bottom: 120px;

    @include media-breakpoint-down('lg') {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .logo-shield {
    max-width: 120px;
  }
}

.verify-product {
  img {
    max-height: 300px;
    object-fit: contain;
  }
}
