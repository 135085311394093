@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../colors";

.page-banner-title {
  &.verify {
    background-image: url(/web/front/banners/verify-bg.jpg);
  }
}

.verify-input-group {
  @include media-breakpoint-down('lg') {
    input.form-control {
      width: 100%;
    }
    button {
      width: 100%;
      font-size: 14px;
    }
  }
}

.btn.verify-amazon {
  letter-spacing: 0.25em;
}

.join {
  padding: 120px;
  background-image: url(/web/front/verify-join-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include media-breakpoint-down('lg') {
    padding: 40px 24px;
    background-position: left center;
  }

  .learn-more {
    width: fit-content;
    i {
      background-color: $blue-700;
    }
    &:hover {
      background-color: #fff;
      color: $primary;
    }
  }
}
