@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.page-banner-title {
  &.checkout {
    background-image: url(/web/front/banners/checkout-bg.jpg);
  }
}

.btn-link.premium-options {
  .bi-plus-circle-fill {
    display: none;
  }

  .bi-dash-circle-fill {
    display: inline;
  }

  &.collapsed {
    .bi-plus-circle-fill {
      display: inline;
    }

    .bi-dash-circle-fill {
      display: none;
    }
  }
}

.paypal.btn {
  img {
    height: 1.5rem;
  }
}

.entered_coupon {
  max-width: 50%;

  input {
    padding-right: 36.5px;
    border-radius: 0;

    & + .invalid-feedback {
      display: block;
      height: 1rem;
    }
  }

  & + button[type='submit'] {
    height: 38px;
    margin-bottom: calc(4px + 1rem);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.checkout-premium {
  display: flex;
  align-items: center;
  gap: 12px;

  input {
    margin-top: 0;
  }

  .checkout-protection {
    .hint {
      display: none;
      background-color: #212121;
      color: #fff;
      font-family: 'Inter', sans-serif;
      padding: 8px;
      transform: translate(100%, -50%);
      border-radius: 8px;
      font-size: 14px;
      max-width: 600px;
      width: 75vw;
    }

    &:hover {
      position: relative;
      cursor: pointer;

      .hint {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
      }
    }
  }
}

.checkout {
  .nav.nav-pills {
    gap: 16px;
    .nav-item {
      flex: 1 0 calc(50% - 8px);
      .paypal {
        img {
          width: auto;
        }
      }
    }
  }
}
